import { call, put } from "redux-saga/effects";
import { YACHT_TYPE } from "../constants/actionTypes";
import ApiYachtType from "../api/yachtType";

export function* yachtType(action) {
  const yachtType = yield call(ApiYachtType.getList, action.payload.lang);

  // save the locations in state
  yield put({
    type: YACHT_TYPE,
    yachtType: yachtType,
  });
}
