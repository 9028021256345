import { call, put } from "redux-saga/effects";
import { YACHT_BRANDS } from "../constants/actionTypes";
import ApiBrands from "../api/brands";

export function* brands(action) {
  const brands = yield call(ApiBrands.brands);

  yield put({
    type: YACHT_BRANDS,
    brands: brands,
  });
}
