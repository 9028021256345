import React from "react";

const Navtika = () => (
  <svg
    className="navtika-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750.12 140.25"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_2"
        x1="46.45"
        y1="126.19"
        x2="178.49"
        y2="126.19"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fa8e00" />
        <stop offset="0.5" stopColor="#ffbf00" />
        <stop offset="1" stopColor="#fa8f00" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_1"
        x1="47.98"
        y1="123.99"
        x2="178.49"
        y2="123.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffc573" />
        <stop offset="0.5" stopColor="#ffc933" />
        <stop offset="1" stopColor="#ffc573" />
      </linearGradient>
      <linearGradient
        id="linear-gradient"
        y1="113.42"
        x2="179.32"
        y2="113.42"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#004080" />
        <stop offset="0.5" stopColor="#0080ff" />
        <stop offset="1" stopColor="#004080" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        y1="109.68"
        x2="179.03"
        y2="109.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#6994bf" />
        <stop offset="0.5" stopColor="#53a8fc" />
        <stop offset="1" stopColor="#6994bf" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="120.42"
        y1="105.88"
        x2="137.46"
        y2="9.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#c6c9cc" />
        <stop offset="1" stopColor="#eef2f5" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="130.27"
        y1="102.49"
        x2="130.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#c1c4c7" />
        <stop offset="1" stopColor="#e4ebf0" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5"
        x1="42.76"
        y1="104.04"
        x2="61.28"
        y2="-0.98"
      >
        <stop offset="0" stopColor="#c6c9cc" />
        <stop offset="1" stopColor="#eef2f5" />
      </linearGradient>
    </defs>
    <title>E-navtika</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M46.45,114.47c49.73,15.06,44.32,50.81,132-2.34C104.64,152.61,97.61,121.08,46.45,114.47Z"
        />
        <path
          className="cls-2"
          d="M48,114.68c48.82,9.5,48.17,43.77,130.26-2.4l.26-.15C105.39,152.2,97.76,121.71,48,114.68Z"
        />
        <path
          className="cls-3"
          d="M0,110.45c74.45-31.8,96.1,25,179.32-4.61C86.79,157.26,96.15,94,0,110.45Z"
        />
        <path
          className="cls-4"
          d="M0,110.45c86.61-28,85.11,34,179-4.51C96.05,135.28,74.37,78.69,0,110.45Z"
        />
        <path
          className="cls-5"
          d="M175.48,100c-3.22-7-29-59.57-90.43-100,13.59,43.52-4.45,88.29-7.86,96.1C105,102.56,131.33,113.75,175.48,100Z"
        />
        <path
          className="cls-6"
          d="M175.48,100c-3.22-7-29-59.57-90.43-100l.06.2c9.15,7.47,56.67,47.93,81.66,102.28Q171,101.43,175.48,100Z"
        />
        <path
          className="cls-7"
          d="M70.65,94.69C72.93,89.79,92.47,45.8,81.4,2.56,68.5,45.43,20.5,91.28,12.51,98.71,35.76,90.58,53.76,91.36,70.65,94.69Z"
        />
        <path
          className="cls-8"
          d="M257.78,38.79q14.71,0,20.69,5.26,6.39,5.67,6.39,20.08,0,2.71-.1,4.14H226v2.92q0,9.07,4.9,12.5,4.09,2.82,13.64,2.82h14Q276,86.5,276,77.41v-.56h8.89v1.38Q284.85,94,260.49,94H244.4q-13.64,0-19.87-4.7-7.15-5.36-7.15-18.49V62.23q0-13.28,7-18.7,6.18-4.75,20-4.75ZM226,60.55h49.91V59.27q0-8.17-5.31-10.83-3.58-1.79-13-1.79H244.24q-9.09,0-13.08,2.4Q226,52.17,226,60.55Z"
        />
        <path
          className="cls-9"
          d="M299.11,93.29V39.5h8.48v10q3.52-6.59,11.9-9,5.62-1.63,15.38-1.63,14.25,0,20.43,2.86,9.3,4.29,9.3,16.6v35h-8.48V60.55q0-7.92-3.58-10.47-4.65-3.32-20.08-3.32-11.14,0-16.4,2-8.48,3.22-8.48,12.41V93.29Z"
        />
        <path
          className="cls-9"
          d="M420,38.79q11.54,0,17.32,4,6.84,4.8,6.84,15.73V93.29H436v-7.1a12.78,12.78,0,0,1-8.12,6.69A39.75,39.75,0,0,1,417.06,94h-14.3q-12.16,0-17.47-2.76-7.46-3.88-7.46-14.71,0-10.37,7.66-14,5.26-2.55,17.06-2.55h15.07q13.08,0,18.39,6.39V59q0-7.71-5.11-10.32-3.52-1.79-12.26-1.79H405.87q-7.92,0-10,.51-5.42,1.38-5.41,6.79v1.58h-9V54.27q0-9.3,7.3-12.92,5.21-2.55,15.73-2.55ZM435.5,79v-4.7q0-5.06-6.9-6.49a61.39,61.39,0,0,0-10.78-.61H405q-9.09,0-11.65.71-6.13,1.69-6.13,8.17,0,7.31,5.57,9.14,2.5.82,12.21.82h12.87a65.72,65.72,0,0,0,10.78-.56Q435.5,84.1,435.5,79Z"
        />
        <path
          className="cls-9"
          d="M481,93.29,451.79,39.5h9.47l25.15,45.75L511.79,39.5h9.58L491.54,93.29Z"
        />
        <path
          className="cls-9"
          d="M542,39.5h35.4v7.25H542V75q0,7.61,4.33,9.91,2.9,1.58,11.56,1.58,7.74,0,10.33-1.63,3.51-2.14,3.51-9.25v-6.9h7.41v6.9q0,11-6.39,15.12Q567.65,94,555.8,94,543,94,537.56,88.7a12.16,12.16,0,0,1-3.37-6.64,44.24,44.24,0,0,1-.46-7.61V46.76H522.19V39.5h11.54V27.19H542Z"
        />
        <path
          className="cls-9"
          d="M591.3,27.4V17.23h8.58V27.4Zm0,65.9V39.5h8.58V93.29Z"
        />
        <path
          className="cls-9"
          d="M627,17.23V59.17h5.57L662.36,39.5h13.27L639.72,63.06l42.47,30.24h-13l-36.83-26H627v26h-8.43V17.23Z"
        />
        <path
          className="cls-9"
          d="M726,38.79q11.54,0,17.32,4,6.84,4.8,6.84,15.73V93.29h-8.17v-7.1a12.78,12.78,0,0,1-8.12,6.69A39.75,39.75,0,0,1,723,94h-14.3q-12.16,0-17.47-2.76-7.46-3.88-7.46-14.71,0-10.37,7.66-14,5.26-2.55,17.06-2.55h15.07q13.08,0,18.39,6.39V59q0-7.71-5.11-10.32-3.52-1.79-12.26-1.79H711.81q-7.92,0-10,.51-5.42,1.38-5.42,6.79v1.58h-9V54.27q0-9.3,7.3-12.92,5.21-2.55,15.73-2.55ZM741.43,79v-4.7q0-5.06-6.9-6.49a61.39,61.39,0,0,0-10.78-.61H710.89q-9.09,0-11.65.71-6.13,1.69-6.13,8.17,0,7.31,5.57,9.14,2.5.82,12.21.82h12.87a65.72,65.72,0,0,0,10.78-.56Q741.43,84.1,741.43,79Z"
        />
      </g>
    </g>
  </svg>
);

export default Navtika;
