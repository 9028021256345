import { WISHLIST_CLICKED, WISHLIST_API } from "../constants/actionTypes";

export default function wishlist(state = {}, action) {
  switch (action.type) {
    case WISHLIST_CLICKED:
      return {
        ...state,
        ...action.payload,
      };

    case WISHLIST_API:
      return {
        ...state,
        ...action.payload,
      };
    // initial state
    default:
      return state;
  }
}
