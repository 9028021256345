import { YACHT_BRANDS } from "../constants/actionTypes";

// locations reducer
export default function brands(state = [], action) {
  switch (action.type) {
    case YACHT_BRANDS:
      return action.brands || [];

    // initial state
    default:
      return state;
  }
}
