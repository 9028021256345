import React from "react";

const FreeIcon = () => (
  <svg
    className="free-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 86"
  >
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M32.69,0,.69,10A1,1,0,0,0,0,11V49c0,7.47,4.47,14.18,10.69,20.25S24.93,80.81,32.44,85.81A1,1,0,0,0,33,86a1,1,0,0,0,.56-.19c7.51-5,15.53-10.49,21.75-16.56S66,56.47,66,49V11a1,1,0,0,0-.69-1l-32-10A1,1,0,0,0,33,0,1.07,1.07,0,0,0,32.69,0ZM33,2l31,9.69V49c0,6.67-4,12.92-10.06,18.81-5.88,5.74-13.61,11-20.94,15.94-7.33-4.89-15.06-10.19-20.94-15.94C6,61.92,2,55.67,2,49V11.72ZM44.91,20a1,1,0,0,0-.72.44l-24,34a1,1,0,1,0,1.63,1.16l24-34A1.06,1.06,0,0,0,44.91,20ZM24,20a6,6,0,1,0,6,6A6,6,0,0,0,24,20Zm0,2a4,4,0,1,1-4,4A4,4,0,0,1,24,22ZM42,44a6,6,0,1,0,6,6A6,6,0,0,0,42,44Zm0,2a4,4,0,1,1-4,4A4,4,0,0,1,42,46Z"
        />
      </g>
    </g>
  </svg>
);

export default FreeIcon;
