import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LayoutQuickSearchSidebar } from "components/BaseLayout";
import PartnerForm from "components/PartnerForm";
import { createPartner } from "api/base";

function PartnersPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("partners_seo_title")}</title>
        <meta name="description" content={t("partners_seo_desc")} />
      </Helmet>
      <LayoutQuickSearchSidebar>
        <div class="page-inner">
          <h1>{t("for_partners")}</h1>
          <p>
            <span dangerouslySetInnerHTML={{ __html: t("partners_text") }} />
          </p>
          <PartnerForm onSubmit={createPartner} />
        </div>
      </LayoutQuickSearchSidebar>
    </>
  );
}

export default PartnersPage;
