import { useState, useEffect } from "react";

function getWindowDimensions() {
  if (!window) {
    return
  }
  const width = window.innerWidth
  const height = window.innerHeight
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
