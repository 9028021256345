import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "components/Home/Header";
import { useAPI } from "utils/hooks";
import { getDestinations } from "api/search";
import getSpecialOffers from "api/specialOffers";
import {
  getHomeBlogs,
  getTestimonials,
  subscribeNewsletter,
  getFeaturedYachts,
} from "api/base";
import Destinations from "components/Destinations";
import DisplayComponent from "components/DisplayComponent";
import HomeSpecialOffers from "components/Home/HomeSpecialOffers";
import HomeIcons from "components/Home/HomeIcons";
import HomeBlogs from "components/Home/HomeBlogs";
import Testimonials from "components/Testimonials";
import HomeNewsletter from "components/Home/HomeNewsletter2";
import FeaturedYachts from "components/FeaturedYachts";
import { searchDestinations } from "api/search";
import { OFFERS_URL } from "constants/index";
import { valuesToSearch } from "utils/search_utils";

const HomePage = ({ yachtType }) => {
  let history = useHistory();
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const destinations = useAPI(getDestinations, lang, true);
  const specialOffers = useAPI(getSpecialOffers, lang);
  const blogs = useAPI(getHomeBlogs, lang);
  const testimonials = useAPI(getTestimonials, lang);
  const featuredYachts = useAPI(getFeaturedYachts);
  return (
    <div className="page-home">
      <Helmet>
        <title>{t("seo_home_title")}</title>
        <meta name="description" content={t("seo_home_description")} />
      </Helmet>
      <Header
        yachtType={yachtType}
        searchDestinations={searchDestinations}
        onSearch={(values) => {
          history.push(OFFERS_URL + "?" + valuesToSearch(values));
        }}
      />

      {specialOffers.data && (
        <HomeSpecialOffers specialOffers={specialOffers.data.results} />
      )}

      <Container className="page-home__block">
        <div className="page-home__title">
          <h2>{t("page_home_featured_yachts")}</h2>
        </div>
        <DisplayComponent
          source={featuredYachts}
          render={(data) => <FeaturedYachts items={data.results} />}
        />
      </Container>

      <Container className="page-home__block">
        <HomeIcons />
      </Container>

      <Container className="page-home__block">
        <div className="page-home__title">
          <h2>{t("page_destinations_title")}</h2>
        </div>
        <DisplayComponent
          source={destinations}
          render={(data) => <Destinations items={data.results} />}
        />
      </Container>

      {testimonials.data && <Testimonials items={testimonials.data.results} />}

      <DisplayComponent
        source={blogs}
        render={(data) => <HomeBlogs items={data.results} />}
      />

      <HomeNewsletter onSubmit={subscribeNewsletter} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    yachtType: state.yachtType,
  };
}
export default connect(mapStateToProps)(HomePage);
