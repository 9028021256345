import React from "react";

const SailmasterItLe = () => (
  <svg
    className="sailmaster-it-le"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 505.46 154.2"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-5"
          d="M65.38,20.14s62.86,25.26,59.87,58.73-61.31,59.84-61.31,59.84,21-26.81,20.05-59.05S65.38,20.14,65.38,20.14Z"
        />
        <path
          className="cls-5"
          d="M65.38,20.14a174.55,174.55,0,0,1,20.06,9.51Q90.3,32.33,95,35.34c3.13,2,6.18,4.13,9.1,6.43a89.58,89.58,0,0,1,8.4,7.37,64.54,64.54,0,0,1,7.27,8.55,40.1,40.1,0,0,1,5.11,10.1,28.4,28.4,0,0,1,1.4,11.38l-.38,2.74c-.18.93-.43,1.88-.65,2.82s-.57,1.82-.85,2.73a24.29,24.29,0,0,1-1,2.65,51.57,51.57,0,0,1-5.65,9.8,84.45,84.45,0,0,1-7.27,8.58c-2.61,2.68-5.38,5.2-8.23,7.6s-5.83,4.66-8.85,6.84-6.12,4.25-9.29,6.19-6.38,3.82-9.65,5.6-6.56,3.47-10,5l-4.23,2L63,138c1.53-2,3-4.22,4.37-6.43.7-1.1,1.34-2.24,2-3.35l1.89-3.43L73,121.29c.58-1.17,1.09-2.36,1.65-3.55s1-2.4,1.52-3.6,1-2.42,1.4-3.66.89-2.45,1.27-3.7L80,103c.39-1.25.63-2.53,1-3.79s.63-2.53.81-3.82l.66-3.85c.16-1.29.27-2.59.4-3.88L83,85.75c0-.65,0-1.3.06-1.95l.09-3.91c0-1.26-.13-2.61-.18-3.92s-.12-2.63-.3-3.92l-.43-3.88-.64-3.87-.32-1.94-.42-1.91c-.28-1.28-.54-2.57-.85-3.84-.67-2.54-1.28-5.09-2.09-7.59s-1.63-5-2.51-7.46L74,37.88l-1.52-3.64-1.62-3.6-1.72-3.55C68,24.74,66.73,22.4,65.38,20.14Zm0,0c1.44,2.21,2.77,4.49,4,6.8S71.86,31.62,73,34l1.64,3.61,1.53,3.66c1,2.46,1.93,4.92,2.74,7.44s1.59,5,2.32,7.59c.33,1.28.62,2.58.93,3.86l.46,1.94.37,2L83.69,68l.51,4c.2,1.32.3,2.64.38,4s.19,2.6.25,4l0,4c0,.66,0,1.33,0,2l-.16,2c-.12,1.32-.2,2.66-.34,4l-.61,4c-.16,1.33-.47,2.62-.76,3.93s-.52,2.62-.91,3.89l-1.1,3.84c-.38,1.28-.85,2.53-1.27,3.78s-.9,2.51-1.4,3.74-1,2.48-1.53,3.69-1.07,2.44-1.65,3.63l-1.78,3.57-1.91,3.5c-.68,1.14-1.32,2.31-2,3.44-1.39,2.28-2.85,4.48-4.48,6.66l-1.41-1.79c3.29-1.54,6.57-3.26,9.79-5s6.39-3.64,9.5-5.59,6.2-4,9.18-6.08,5.93-4.33,8.74-6.67,5.53-4.8,8.11-7.39a80.67,80.67,0,0,0,7.11-8.29,49.31,49.31,0,0,0,5.47-9.34,23.26,23.26,0,0,0,1-2.51c.27-.85.64-1.69.82-2.55s.44-1.69.61-2.58l.4-2.74A27.26,27.26,0,0,0,123,68.38a38.43,38.43,0,0,0-4.71-9.71,62.8,62.8,0,0,0-6.93-8.45,88.47,88.47,0,0,0-8.14-7.4A151.87,151.87,0,0,0,85.06,30.31q-4.77-2.8-9.7-5.35C72.08,23.27,68.77,21.62,65.38,20.14Z"
        />
        <polygon
          className="cls-6"
          points="57.2 0 60.89 124.5 54.7 0.1 57.2 0"
        />
        <path
          className="cls-6"
          d="M75.41,141.54l1.42.06c.92.05,2.28.1,4,.26s3.81.34,6.21.68a56,56,0,0,1,8.06,1.61,16.8,16.8,0,0,1,2.33.87,6.21,6.21,0,0,1,1.3.8,3.28,3.28,0,0,1,.73.83,2.48,2.48,0,0,1,.33,1.67,2.73,2.73,0,0,1-.67,1.37,3.87,3.87,0,0,1-.76.67,8.35,8.35,0,0,1-1.37.76,21.09,21.09,0,0,1-2.58.92,52.22,52.22,0,0,1-10.82,2,77.9,77.9,0,0,1-11.69-.07c-3.94-.3-7.9-.82-11.83-1.45s-7.82-1.48-11.62-2.36S41,148.24,37.4,147.23s-7-2.09-10.2-3.17-6.25-2.13-9-3.18c-5.56-2-10.09-4-13.25-5.38S0,133.22,0,133.22l19,4.93,9.2,2.33L38.51,143c3.55.87,7.26,1.61,11,2.44s7.57,1.52,11.39,2.15,7.65,1.23,11.42,1.58a92.08,92.08,0,0,0,11,.5,59.76,59.76,0,0,0,10.15-1.19,17.63,17.63,0,0,0,2.17-.6,5.23,5.23,0,0,0,.81-.36c.21-.08.2-.25.06.24a.77.77,0,0,0,.11.53l0-.06a2.89,2.89,0,0,0-.63-.47,12.44,12.44,0,0,0-1.82-.9,73.47,73.47,0,0,0-7.5-2.44c-2.3-.62-4.32-1.15-6-1.57Z"
        />
        <path
          className="cls-3"
          d="M108,103.74c2.8-4.59,8.27-14.35,9.87-22.8a31.26,31.26,0,0,0-1.16-14.8c2.65,5.44,5.09,13.11,2.87,20.39A35.11,35.11,0,0,1,108,103.74Z"
        />
        <path
          className="cls-4"
          d="M112.64,58.3s6.72,11.23,4.58,22.52S105.77,106,105.77,106s10.19-5.65,14.37-19.32S112.64,58.3,112.64,58.3Z"
        />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <path
          className="cls-2"
          d="M173.71,68.65v23h-5.07v-23h-9.11v-4.2h23.29v4.2Z"
        />
        <path
          className="cls-2"
          d="M187.62,91.66V69h4.22v9.83h12.42V69h4.22V91.66h-4.22V82.39H191.85v9.28Z"
        />
        <path
          className="cls-2"
          d="M233,69v3.6H219.67v6h12V82h-12v6.07h13.78v3.6h-18V69Z"
        />
        <path
          className="cls-1"
          d="M248.64,68a7.21,7.21,0,0,0-3.63.82,2.67,2.67,0,0,0-1.42,2.47A2.8,2.8,0,0,0,245,73.85a22.9,22.9,0,0,0,6,1.89,17.44,17.44,0,0,1,7,2.84A6.43,6.43,0,0,1,260.34,84a6.9,6.9,0,0,1-3,5.78A12.64,12.64,0,0,1,249.6,92a19.36,19.36,0,0,1-12.5-4.4l3.18-3.47a15.11,15.11,0,0,0,9.45,3.58,7,7,0,0,0,3.89-1,2.91,2.91,0,0,0,1.44-2.53,2.77,2.77,0,0,0-1.35-2.45,15.18,15.18,0,0,0-4.66-1.6,45.15,45.15,0,0,1-5-1.32,11.69,11.69,0,0,1-3-1.58,6.34,6.34,0,0,1-2.66-5.61,6.61,6.61,0,0,1,3-5.82,13.1,13.1,0,0,1,7.5-2,19.67,19.67,0,0,1,5.71.86,15.47,15.47,0,0,1,4.9,2.41l-2.71,3.47a10.6,10.6,0,0,0-3.61-1.79A15.21,15.21,0,0,0,248.64,68Z"
        />
        <path
          className="cls-1"
          d="M269.47,86.51,267,91.66h-4.51l11-22.68H278l11,22.68H284.5L282,86.51ZM280.28,83l-4.55-9.34L271.18,83Z"
        />
        <path className="cls-1" d="M292.48,69h4.22V91.66h-4.22Z" />
        <path className="cls-1" d="M303.65,91.66V69h4.23V88H319.3v3.63Z" />
        <path
          className="cls-1"
          d="M328.14,91.66h-4.22V69h6.59l7.55,14.24L345.61,69h6.55V91.66h-4.22V73.95l-8.74,15.74h-2.36l-8.7-15.74Z"
        />
        <path
          className="cls-1"
          d="M362.65,86.51l-2.5,5.16h-4.51l11-22.68h4.51l11,22.68h-4.51l-2.51-5.16ZM373.47,83l-4.55-9.34L364.37,83Z"
        />
        <path
          className="cls-1"
          d="M393.24,72a6,6,0,0,0-3,.68A2.23,2.23,0,0,0,389,74.71a2.34,2.34,0,0,0,1.18,2.11,19.12,19.12,0,0,0,5,1.57,14.55,14.55,0,0,1,5.8,2.37,5.36,5.36,0,0,1,2,4.49,5.75,5.75,0,0,1-2.47,4.82,10.55,10.55,0,0,1-6.48,1.85,16.12,16.12,0,0,1-10.42-3.67l2.65-2.89a12.59,12.59,0,0,0,7.88,3,5.79,5.79,0,0,0,3.24-.8,2.43,2.43,0,0,0,1.2-2.11,2.31,2.31,0,0,0-1.13-2,12.64,12.64,0,0,0-3.88-1.33A37.62,37.62,0,0,1,389.4,81a9.74,9.74,0,0,1-2.54-1.31A5.28,5.28,0,0,1,384.63,75a5.51,5.51,0,0,1,2.53-4.85,10.91,10.91,0,0,1,6.25-1.7,16.4,16.4,0,0,1,4.76.71,12.86,12.86,0,0,1,4.08,2L400,74a8.82,8.82,0,0,0-3-1.49A12.7,12.7,0,0,0,393.24,72Z"
        />
        <path
          className="cls-1"
          d="M417.68,72.49V91.66h-4.22V72.49h-7.59V69h19.4v3.5Z"
        />
        <path
          className="cls-1"
          d="M447.43,69v3.6H434.07v6h12V82h-12v6.07h13.79v3.6h-18V69Z"
        />
        <path
          className="cls-1"
          d="M473.24,76.35q0,5.61-5.4,7.07l6.55,8.24H469L463,84h-5.55v7.62h-4.23V69h9.31q5.73,0,8.2,1.75T473.24,76.35Zm-5.62,3.21a3.84,3.84,0,0,0,1.29-3.23,3.31,3.31,0,0,0-1.33-3.05,9.58,9.58,0,0,0-4.73-.83h-5.37v8.11h5.26Q466.33,80.57,467.62,79.56Z"
        />
        <path
          className="cls-2"
          d="M478.67,91.26a2,2,0,0,1-.69-1.55,2,2,0,0,1,.69-1.53,2.42,2.42,0,0,1,1.69-.63,2.49,2.49,0,0,1,1.71.63,2,2,0,0,1,.71,1.53,2,2,0,0,1-.71,1.55,2.47,2.47,0,0,1-1.71.64A2.4,2.4,0,0,1,478.67,91.26Z"
        />
        <path
          className="cls-2"
          d="M486.61,73a1.95,1.95,0,0,1,0-2.93,2.34,2.34,0,0,1,3.16,0,1.95,1.95,0,0,1,0,2.93,2.34,2.34,0,0,1-3.16,0Zm3.32,18.69h-3.54V76.15h3.54Z"
        />
        <path
          className="cls-2"
          d="M499.42,78.79V86.7a2.43,2.43,0,0,0,.63,1.77,2.32,2.32,0,0,0,1.77.66,3.11,3.11,0,0,0,2.18-1l1.45,2.32A6.3,6.3,0,0,1,501.33,92a5.56,5.56,0,0,1-3.86-1.45,5,5,0,0,1-1.6-3.91V78.79h-2.12V76.15h2.12V71.26h3.54v4.89h4.43v2.64Z"
        />
        <path
          className="cls-2"
          d="M163.1,115.14V105.06h1.6l3.64,5.1a27.68,27.68,0,0,1,2,3.27l0,0a39.88,39.88,0,0,1-.17-4.14v-4.22h1.38v10.07h-1.48l-3.6-5.11a31.34,31.34,0,0,1-2.12-3.36h-.05c.08,1.27.12,2.48.12,4.16v4.3Z"
        />
        <path
          className="cls-2"
          d="M197.36,110a5,5,0,0,1-5.27,5.31,4.87,4.87,0,0,1-5.1-5.11,5,5,0,0,1,5.27-5.29A4.83,4.83,0,0,1,197.36,110Zm-8.81.16c0,2.15,1.31,4.08,3.62,4.08s3.64-1.9,3.64-4.18c0-2-1.18-4.1-3.62-4.1S188.55,108,188.55,110.16Z"
        />
        <path className="cls-2" d="M212.74,105.06h1.47v9h4.85v1.09h-6.32Z" />
        <path
          className="cls-2"
          d="M240,110.42h-4.41V114h4.92v1.09h-6.38V105.06h6.13v1.09h-4.67v3.18H240Z"
        />
        <path
          className="cls-2"
          d="M264.56,114.69a11.8,11.8,0,0,1-3.49.55,6.29,6.29,0,0,1-4.24-1.33,4.87,4.87,0,0,1-1.58-3.75c0-3,2.34-5.2,6.15-5.2a7.75,7.75,0,0,1,2.83.46l-.35,1.06a6.51,6.51,0,0,0-2.51-.43c-2.76,0-4.56,1.53-4.56,4.05s1.74,4.06,4.38,4.06a5.35,5.35,0,0,0,2-.27v-3h-2.31v-1h3.74Z"
        />
        <path
          className="cls-2"
          d="M288.92,114.69a11.81,11.81,0,0,1-3.49.55,6.29,6.29,0,0,1-4.24-1.33,4.87,4.87,0,0,1-1.58-3.75c0-3,2.34-5.2,6.15-5.2a7.77,7.77,0,0,1,2.83.46l-.36,1.06a6.5,6.5,0,0,0-2.51-.43c-2.76,0-4.56,1.53-4.56,4.05s1.74,4.06,4.38,4.06a5.35,5.35,0,0,0,2-.27v-3h-2.31v-1h3.74Z"
        />
        <path className="cls-2" d="M306.09,105.06v10.07h-1.46V105.06Z" />
        <path
          className="cls-2"
          d="M331.83,110a5,5,0,0,1-5.27,5.31,4.87,4.87,0,0,1-5.1-5.11,5,5,0,0,1,5.27-5.29A4.84,4.84,0,0,1,331.83,110Zm-8.81.16c0,2.15,1.31,4.08,3.62,4.08s3.64-1.9,3.64-4.18c0-2-1.18-4.1-3.62-4.1S323,108,323,110.16Z"
        />
        <path
          className="cls-2"
          d="M380.09,105.21a15.94,15.94,0,0,1,2.68-.21,5.13,5.13,0,0,1,3.11.73,2,2,0,0,1,.94,1.78,2.45,2.45,0,0,1-1.92,2.21v0a2.71,2.71,0,0,1,2.34,2.53,2.58,2.58,0,0,1-1,2,6.23,6.23,0,0,1-3.92.94,19.15,19.15,0,0,1-2.27-.12Zm1.46,4.12h1.33c1.55,0,2.46-.72,2.46-1.69,0-1.18-1-1.65-2.49-1.65a7.24,7.24,0,0,0-1.3.09Zm0,4.83a8.78,8.78,0,0,0,1.23.06c1.52,0,2.91-.49,2.91-2s-1.33-1.94-2.93-1.94h-1.21Z"
        />
        <path
          className="cls-2"
          d="M404.53,112l-1.18,3.17h-1.52l3.86-10.07h1.77l3.87,10.07h-1.57L408.56,112Zm3.72-1-1.11-2.9c-.25-.66-.42-1.26-.59-1.84h0c-.17.6-.35,1.21-.57,1.82L404.84,111Z"
        />
        <path
          className="cls-2"
          d="M426.48,105.21a17.06,17.06,0,0,1,2.81-.21,5.05,5.05,0,0,1,3.28.82,2.38,2.38,0,0,1,.89,1.91,2.71,2.71,0,0,1-2.14,2.57v0a2.57,2.57,0,0,1,1.67,2,14.74,14.74,0,0,0,.88,2.77h-1.52a12,12,0,0,1-.76-2.41c-.34-1.39-.94-1.91-2.27-2h-1.38v4.36h-1.47Zm1.47,4.59h1.5c1.57,0,2.56-.76,2.56-1.91,0-1.3-1.06-1.87-2.61-1.88a6.65,6.65,0,0,0-1.45.12Z"
        />
        <path
          className="cls-2"
          d="M457,114.82a8.1,8.1,0,0,1-3,.48c-3.2,0-5.61-1.79-5.61-5.1s2.41-5.29,5.93-5.29a6.76,6.76,0,0,1,2.69.45l-.35,1.06a5.91,5.91,0,0,0-2.29-.42c-2.66,0-4.43,1.51-4.43,4.16,0,2.47,1.6,4.05,4.36,4.05a6.51,6.51,0,0,0,2.39-.42Z"
        />
        <path
          className="cls-2"
          d="M473.77,105.08v4.22h5.49v-4.22h1.48v10.07h-1.48v-4.72h-5.49v4.72H472.3V105.08Z"
        />
        <path
          className="cls-2"
          d="M502.64,110.43h-4.41v3.63h4.92v1.09h-6.38V105.08h6.13v1.09h-4.67v3.18h4.41Z"
        />
      </g>
    </g>
  </svg>
);

export default SailmasterItLe;
