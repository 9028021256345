import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HandLens from "../icons/HandLens";
import Calendar from "../icons/Calendar";
import Reservation from "../icons/Reservation";
import FreeIcon from "../icons/FreeIcon";
import HomeIcon from "./HomeIcon";

export default function HomeIcons() {
  return (
    <>
      <Row>
        <Col xs={12} sm={3} className="home-icon-wrapper">
          <HomeIcon
            title="home_icon_1_title"
            link="home_icon_1_link"
            text="home_icon_1_text"
          >
            <HandLens />
          </HomeIcon>
        </Col>
        <Col xs={12} sm={3} className="home-icon-wrapper">
          <HomeIcon
            title="home_icon_2_title"
            link="home_icon_2_link"
            text="home_icon_2_text"
          >
            <Calendar />
          </HomeIcon>
        </Col>
        <Col xs={12} sm={3} className="home-icon-wrapper">
          <HomeIcon
            title="home_icon_3_title"
            link="home_icon_3_link"
            text="home_icon_3_text"
          >
            <Reservation />
          </HomeIcon>
        </Col>
        <Col xs={12} sm={3} className="home-icon-wrapper">
          <HomeIcon
            title="home_icon_4_title"
            link="home_icon_4_link"
            text="home_icon_4_text"
          >
            <FreeIcon />
          </HomeIcon>
        </Col>
      </Row>
    </>
  );
}
