import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import ScrollToTop from "react-scroll-to-top";

import Routes from "./router.js";
import Navigation2 from "components/Navigation2";
import Footer from "components/common/Footer";
import CookieLaw from "components/common/CookieLaw";
import { wishlistClickedReducerAction } from "actions/wishlist";
import { getWishlistFromLocalStorage } from "utils/wishlistUtils";
import InquiryModal from "components/Inquiry";
import { createInquiry } from "api/base";
import PromoModal from "components/PromoModal";
import { subscribeNewsletterGift } from "api/base";
import ErrorBoundary from "components/ErrorBoundary";

import "./stylesheets/main.scss";

const App = ({ dispatch, countries }) => {
  const [showInquiry, setShowInquiry] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language
  const { listen } = useHistory()

  useEffect(() => {
    const unlisten = listen((location) => {
      setTimeout(() => {
      window.ga.getAll()[0].set("page", location.pathname + location.search);
      window.ga.getAll()[0].send("pageview");
      }, 0)
    })
    return unlisten
  }, [listen])

  useEffect(() => {
    if (!localStorage.getItem("promoModal")) {
      setTimeout(() => {
        setShowModal(true)
        localStorage.setItem("promoModal", 1)
      }, 1000)
    }

    dispatch({
      type: "COUNTRIES_ENQUIRY_SAGA",
      payload: { lang },
    });
    dispatch({
      type: "YACHT_TYPE_FETCH",
      payload: { lang },
    });
    dispatch({
      type: "BRANDS_SAGA",
      payload: { lang },
    });
    const arr = getWishlistFromLocalStorage();
    dispatch(
      wishlistClickedReducerAction({
        count: arr.size,
        array: arr,
        success: true,
      })
    );
  }, [dispatch, lang]);

  return (
    <div>
      <ScrollToTop smooth color="#ceb896" />
      <Navigation2 onShowInquiry={setShowInquiry.bind(true)} />
      <ErrorBoundary>
      <Routes />
      </ErrorBoundary>
      <Footer />
      <CookieLaw text={t("COOKIES")} buttonText={t("CLOSE")} />
      {showModal && <PromoModal
          onClose={setShowModal.bind(false)}
        onSubmit={subscribeNewsletterGift}
      />}
      {showInquiry && (
        <InquiryModal
          onClose={setShowInquiry.bind(false)}
          countries={countries}
          onSubmit={createInquiry}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: state.countriesEnquiry || [],
  }
};
export default connect(mapStateToProps)(App);
