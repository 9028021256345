import React from "react";
import { Row, Col } from "react-bootstrap";

const Loader = () => (
  <div key="loader" id="loader">
    <Row>
      <Col xs={12}>
        <div id="loader">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </Col>
    </Row>
  </div>
);

export default Loader;
