import { call, put } from "redux-saga/effects";
import {
  COUNTRIES_ENQUIRY,
} from "../constants/actionTypes";
import ApiLocations from "../api/locations";

export function* countriesEnquiry(action) {
  const countries = yield call(
    ApiLocations.getCountriesEnquiry,
    action.payload
  );

  yield put({
    type: COUNTRIES_ENQUIRY,
    countriesEnquiry: countries,
  });
}
