import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import OffersPage from "./pages/OffersPage";
import OfferDetailPage from "./pages/OfferDetailPage";
import YachtPage from "./pages/YachtPage";
import DestinationsPage from "./pages/DestinationsPage";
import HomePage from "./pages/HomePage";
import FaqPage from "pages/FaqPage";
import ContactPage from "pages/ContactPage";
import PartnersPage from "pages/PartnersPage";

import Loader from "components/Loader";
//OLD
import Wishlist from "./components/wishlist/Wishlist";
import NotFound from "./components/NotFound";

import {
  OFFERS_URL,
  CONTACT_URL,
  WISHLIST_URL,
  DESTINATIONS_URL,
  FAQ_URL,
  PARTNERS_URL,
} from "./constants";

const TermsSi = lazy(() => import("./components/terms/TermsSi"));
const TermsIt = lazy(() => import("./components/terms/TermsIt"));
const Blog = lazy(() => import("./components/blog/Blog"));
const BlogSingle = lazy(() => import("./components/blog_single/BlogSingle"));
const ReservationStepsSi = lazy(() => import("./components/reservation-steps/ReservationStepsSi"));
const ReservationStepsIt = lazy(() => import("./components/reservation-steps/ReservationStepsIt"));
const PaymentTypeSi = lazy(() => import("./components/payment-type/PaymentTypeSi"));
const PaymentTypeIt = lazy(() => import("./components/payment-type/PaymentTypeIt"));
const PrivacySi = lazy(() => import("./components/privacy/PrivacySi"));
const PrivacyIt = lazy(() => import("./components/privacy/PrivacyIt"));
const WhyUsSi = lazy(() => import("./components/why-us/WhyUsSi"));
const WhyUsIt = lazy(() => import("./components/why-us/WhyUsIt"));
const WhyCharterIt = lazy(() => import("./components/why-charter/WhyCharterIt"));
const WhyCharterSi = lazy(() => import("./components/why-charter/WhyCharterSi"));
const AbousUsSi = lazy(() => import("./components/about-us/AbousUsSi"));
const AbousUsIt = lazy(() => import("./components/about-us/AbousUsIt"));
const InsuranceSi = lazy(() => import("./components/insurance/InsuranceSi"));
const InsuranceIt = lazy(() => import("./components/insurance/InsuranceIt"));


const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route
        path={`${OFFERS_URL}/:yacht_id-:slug/:id(\\d+)`}
        component={OfferDetailPage}
      />
      <Route
        path={`${OFFERS_URL}/:yacht_id-:slug`}
        component={YachtPage}
      />
      <Route path={OFFERS_URL} component={OffersPage} />
      <Route path={WISHLIST_URL} component={Wishlist} />
      <Route path={DESTINATIONS_URL} component={DestinationsPage} />
      <Route path={FAQ_URL} component={FaqPage} />
      <Route path={CONTACT_URL} component={ContactPage} />
      <Route path={PARTNERS_URL} component={PartnersPage} />
      <Route path="/blog/:slug" component={BlogSingle} />
      <Route exact path="/blog" component={Blog} />

      <Route path="/pogoji-uporabe" component={TermsSi} />
      <Route path="/zavarovanja" component={InsuranceSi} />
      <Route path="/assicurazione" component={InsuranceIt} />
      <Route path="/termini-e-condizioni" component={TermsIt} />
      <Route path="/postopek-rezervacije" component={ReservationStepsSi} />
      <Route path="/procedure-di-prenotazione" component={ReservationStepsIt} />
      <Route path="/nacin-placila" component={PaymentTypeSi} />
      <Route path="/modalita-di-pagamento" component={PaymentTypeIt} />
      <Route path="/zaupnost-podatkov" component={PrivacySi} />
      <Route path="/riservatezza-dei-dati" component={PrivacyIt} />
      <Route path="/zakaj-najeti-plovilo-pri-nas" component={WhyUsSi} />
      <Route path="/perche-noleggiare-da-noi" component={WhyUsIt} />
      <Route path="/zakaj-charter" component={WhyCharterSi} />
      <Route path="/perche-charter" component={WhyCharterIt} />
      <Route path="/o-nas" component={AbousUsSi} />
      <Route path="/chi-siamo" component={AbousUsIt} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Suspense>
);

export default Routes;
