import { COUNTRIES } from "../constants/actionTypes";

// locations reducer
export default function countries(state = {}, action) {
  switch (action.type) {
    case COUNTRIES:
      return action.countries || {};

    // initial state
    default:
      return state;
  }
}
