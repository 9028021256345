import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { valuesToSearch, parseDestinations } from "utils/search_utils";
import { OFFERS_URL } from "../constants";

const Destination = ({ destination }) => {
  const style = {
    backgroundImage: `url(${destination.image.thumbnail})`,
  };
  const link =
    OFFERS_URL +
    "?" +
    valuesToSearch({
      destinations: parseDestinations(destination.destination_hash.split("|")),
    });
  return (
    <div className="destination2" style={style}>
      <Link to={link} className="destinations2__link btn btn-lg btn-primary">
        {destination.name}
      </Link>
    </div>
  );
};

const Destinations = ({ items }) => {
  return (
    <div className="destinations2">
      <Row>
        {items.map((destination) => (
          <Col
            key={destination.id}
            sm="6"
            md="4"
            lg="3"
            className="col--destinations2"
          >
            <Destination destination={destination} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Destinations;
