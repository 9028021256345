import React from "react";

const Verified = () => (
  <svg
    className="verified-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 24"
  >
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M11.5,24a2.57,2.57,0,0,1-2.29-1.42h0a1.66,1.66,0,0,0-.83-.91,1.66,1.66,0,0,0-1.2.25h0a2.57,2.57,0,0,1-3.71-2.7h0c.18-1.14-.13-1.56-1.26-1.74h0A2.58,2.58,0,0,1,.76,13.09h0c.81-.81.81-1.34,0-2.15h0A2.58,2.58,0,0,1,2.18,6.54h0c1.13-.18,1.44-.6,1.26-1.74h0a2.57,2.57,0,0,1,3.71-2.7h0a1.65,1.65,0,0,0,1.2.25,1.66,1.66,0,0,0,.83-.91h0a2.56,2.56,0,0,1,4.59,0h0a1.66,1.66,0,0,0,.83.91,1.65,1.65,0,0,0,1.2-.25h0a2.57,2.57,0,0,1,3.71,2.7h0c-.18,1.14.13,1.56,1.26,1.74h0a2.58,2.58,0,0,1,1.42,4.37h0c-.81.81-.81,1.34,0,2.15h0a2.58,2.58,0,0,1-1.42,4.37h0c-1.13.18-1.44.6-1.26,1.74h0a2.57,2.57,0,0,1-3.71,2.7h0a1.65,1.65,0,0,0-1.2-.25,1.66,1.66,0,0,0-.83.91h0A2.57,2.57,0,0,1,11.5,24Zm-1.41-1.88a1.56,1.56,0,0,0,2.81,0h0a2.54,2.54,0,0,1,1.41-1.4,2.54,2.54,0,0,1,2,.31h0a1.57,1.57,0,0,0,1.65-.12,1.58,1.58,0,0,0,.63-1.54h0a2.21,2.21,0,0,1,2.08-2.87h0a1.58,1.58,0,0,0,.87-2.69h0a2.21,2.21,0,0,1,0-3.55h0a1.58,1.58,0,0,0-.87-2.69h0a2.21,2.21,0,0,1-2.08-2.87h0a1.58,1.58,0,0,0-.62-1.54A1.57,1.57,0,0,0,16.29,3h0a2.54,2.54,0,0,1-2,.31,2.55,2.55,0,0,1-1.41-1.4h0a1.57,1.57,0,0,0-2.81,0h0a2.56,2.56,0,0,1-1.41,1.4,2.54,2.54,0,0,1-2-.31h0A1.57,1.57,0,0,0,4.43,4.64h0A2.21,2.21,0,0,1,2.35,7.52h0a1.58,1.58,0,0,0-.87,2.69h0a2.22,2.22,0,0,1,0,3.55h0a1.58,1.58,0,0,0,.87,2.69h0a2.21,2.21,0,0,1,2.08,2.87h0A1.57,1.57,0,0,0,6.71,21h0a2.56,2.56,0,0,1,2-.31,2.55,2.55,0,0,1,1.41,1.4Z"
        />
        <polygon
          className="cls-1"
          points="10.11 15.37 7.29 11.97 8.06 11.34 10.5 14.27 15.43 8.33 16.2 8.96 10.88 15.37 10.11 15.37"
        />
      </g>
    </g>
  </svg>
);

export default Verified;
