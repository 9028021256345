import { call, put } from "redux-saga/effects";
import {
  BLOG,
  BLOG_SINGLE,
  BLOG_PAGE,
  BLOG_PAGE_PAGINATION,
} from "../constants/actionTypes";
import ApiBlog from "../api/blog";

// Watcher saga for the blogs
export function* blog(action) {
  // call the api to get the blogs
  const blog = yield call(ApiBlog.getBlogs, action.payload);

  // save the blogs in state
  yield put({
    type: BLOG,
    blog: blog,
  });
}

export function* blog_page(action) {
  // call the api to get the blogs
  const blog = yield call(ApiBlog.getBlogs, action.payload);

  // save the blogs in state
  yield put({
    type: BLOG_PAGE,
    blogs: blog,
  });
}

export function* blog_page_pagination(action) {
  const blog = yield call(ApiBlog.blogPagination, action.payload.url);

  yield put({
    type: BLOG_PAGE_PAGINATION,
    blogs: blog,
  });
}

export function* blog_single(action) {
  // call the api to get the blogs
  const blog = yield call(ApiBlog.getBlog, action.payload.slug);

  if (blog.error) {
  } else {
    // save the blogs in state
    yield put({
      type: BLOG_SINGLE,
      blog: blog,
    });
  }
}
