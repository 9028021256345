import { takeLatest } from "redux-saga";
import { fork } from "redux-saga/effects";
import { search, searchLoadMore } from "./search";

import { WISHLIST_API_SAGA } from "../constants/actionTypes";
import { yachtType } from "./yachtType";
import { brands } from "./brands";
import { wishlistApi } from "./wishlist";
import { blog, blog_page, blog_page_pagination, blog_single } from "./blog";
import {
  countriesEnquiry,
} from "./locations";

// main saga generators
export function* sagas() {
  yield [
    // new
    fork(takeLatest, "SEARCH", search),
    fork(takeLatest, "SEARCH_LOAD_MORE", searchLoadMore),
    // old
    fork(takeLatest, "YACHT_TYPE_FETCH", yachtType),

    fork(takeLatest, "BRANDS_SAGA", brands),
    fork(takeLatest, "BLOG_SAGA", blog),
    fork(takeLatest, "BLOG_SINGLE_SAGA", blog_single),
    fork(takeLatest, "BLOG_PAGE_SAGA", blog_page),
    fork(takeLatest, "BLOG_PAGE_PAGINATION_SAGA", blog_page_pagination),
    fork(takeLatest, "COUNTRIES_ENQUIRY_SAGA", countriesEnquiry),
    fork(takeLatest, WISHLIST_API_SAGA, wishlistApi),
  ];
}
