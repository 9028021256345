import { BLOG } from "../constants/actionTypes";

// locations reducer
export default function blog(state = [], action) {
  switch (action.type) {
    case BLOG:
      return action.blog || [];

    // initial state
    default:
      return state;
  }
}
