import { useTranslation } from "react-i18next";
import React from "react";
import Container from "react-bootstrap/Container";
import { getDestinations } from "api/search";
import Destinations from "components/Destinations";
import DisplayComponent from "components/DisplayComponent";
import { useAPI } from "utils/hooks";
import { Helmet } from "react-helmet";

const DestinationsPage = () => {
  const { i18n, t } = useTranslation();
  const destinations = useAPI(getDestinations, i18n.language);

  return (
    <Container className="base-layout">
      <Helmet>
        <title>
          {t("seo_destinations_title") + t("seo_title")}
        </title>
        <meta
          name="description"
          content={t("seo_destinations_description")}
        />
      </Helmet>
      <h1>
        {t("page_destinations_title")}
      </h1>
      <DisplayComponent
        source={destinations}
        render={(data) => <Destinations items={data.results} />}
      />
    </Container>
  );
};

export default DestinationsPage;
