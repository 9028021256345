import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Location from "components/Location";
import Properties from "components/Properties";
import { offerUrl } from "utils/url_utils";

const FeaturedYacht = ({ item }) => {
  const { t } = useTranslation();

  const yachProperties = [
    { name: t("yacht_build_year"), value: item.build_year || "/" },
    {
      name: t("number_cabins_berths"),
      value: `${item.cabins_total} / ${item.berths_total}`,
    },
  ];
  return (
    <div className="featured-yacht">
      <div>
        <h3 className="featured-yacht__name">
          <Link
            to={offerUrl(item.yacht_id, item.yacht_model_name, item.offer_id)}
          >
            {item.title}
          </Link>
        </h3>
        {item.price && <h4 className="featured-yacht__price">{item.price}</h4>}
        <Location location={item.location} />
      </div>
      <div>
        {item.image && (
          <div class="embed-responsive embed-responsive-4by3 featured-yacht__image-container">
            <img
              className="embed-responsive-item featured-yacht__image"
              src={item.image}
              alt={item.title}
            />
          </div>
        )}
        <div>
          <Properties items={yachProperties} />
        </div>
      </div>
    </div>
  );
};

const FeaturedYachts = ({ items }) => {
  return (
    <div className="featured-yachts">
      <Row>
        {items
          .filter((item) => !!item.offer_id)
          .map((item) => (
            <Col className="d-flex" key={item.yacht_id} sm={6} md={4} xl={3}>
              <FeaturedYacht item={item} />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default FeaturedYachts;
