import React, {useState} from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Popover from "react-bootstrap/Popover";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";

import { LinkContainer } from "react-router-bootstrap";
import { ReactComponent as Phone } from "icons/telefon.svg";
import { ReactComponent as Heart } from "icons/heart.svg";
import { ReactComponent as LogoSi } from "icons/the-sailmaster-si.svg";
import { ReactComponent as LogoIt } from "icons/the-sailmaster-it.svg";
import { language, WISHLIST_URL } from "../constants";
import QuickContact from "components/QuickContact";

const Logo = process.env.REACT_APP_LANGUAGE === "it" ? LogoIt : LogoSi;

const LANGUAGES = {
  si: {
    show: "SI",
    key: "si",
    url: "http://thesailmaster.si",
  },
  it: {
    show: "IT",
    key: "it",
    url: "http://thesailmaster.it",
  },
};

const Lang = ({ lang }) => {
  return (
    <>
      <img
        src={`/static/media/langs/${lang.key}.svg`}
        className="flag"
        alt={lang.key}
      />
      <span>{lang.show}</span>
    </>
  );
};

export const Navigation2 = ({ wishlistCount, onShowInquiry }) => {
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  const links = [
    {
      link: t("offers_route"),
      name: t("regular_offer"),
    },
    {
      link: t("destinations_route"),
      name: t("destinations"),
    },
    {
      link: t("contact_route"),
      name: t("contact"),
    },
  ];
  const linkHome = "/";

  const popoverContact = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <QuickContact />
    </Popover>
  );

  return (
    <Navbar
      variant="light"
      fixed="top"
      expand="lg"
      className="navbar--navbar2"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand>
          <Link className="d-inline-block" to={linkHome}>
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle 
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Button
              size="sm"
              className="btn--request-offer"
              onClick={onShowInquiry}
            >
              {t("nav_request_offer")}
            </Button>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popoverContact}
            >
              <Button size="sm" variant="light">
                <Phone className="navbar-icon" />
              </Button>
            </OverlayTrigger>
            <LinkContainer
              onClick={() => setExpanded(false)}
              to={WISHLIST_URL}>
              <Button size="sm" variant="light">
                <Heart className="navbar-icon navbar-icon--heart" />
                <Badge pill variant="secondary" className="btn-wishlist__badge">
                  {wishlistCount}
                </Badge>
              </Button>
            </LinkContainer>
            {links.map((link, index) => (
              <Nav.Item
                onClick={() => setExpanded(false)}
                key={index} to={link.link} as={NavLink} className="nav-link">
                {link.name}
              </Nav.Item>
            ))}
            <NavDropdown
              title={<Lang lang={LANGUAGES[language]} />}
              id="basic-nav-dropdown"
            >
              {Object.keys(LANGUAGES).map((language, index) => (
                <Dropdown.Item href={LANGUAGES[language].url} key={index}>
                  <Lang lang={LANGUAGES[language]} />
                </Dropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

function mapStateToProps(state) {
  return {
    wishlistCount: state.wishlist.count,
  };
}
export default connect(mapStateToProps)(Navigation2);
