import React from "react";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import IconWithText from "../common/IconWithText";
import Verified from "../icons/Verified";
import RealTime from "../icons/RealTime";
import { ReactComponent as TrustedDestination } from "icons/brez-stroskov-rezervacije_.svg";
import { ReactComponent as TalkIcon } from "icons/svetovanje-podpora.svg";

const IconsLine = (props) => {
  const iconStyles = {
    height: 51,
    width: "auto",
  };
  return (
    <div className="icons-line">
      <div className="container">
        <Row>
          <Col xs={12} sm={6} md={3} className="icon_with_text_home_1">
            <IconWithText text={props.t("icon_with_text_home_1")}>
              <Verified />
            </IconWithText>
          </Col>
          <Col xs={12} sm={6} md={3} className="icon_with_text_home_2">
            <IconWithText text={props.t("icon_with_text_home_2")}>
              <RealTime />
            </IconWithText>
          </Col>
          <Col xs={12} sm={6} md={3} className="icon_with_text_home_3">
            <IconWithText text={props.t("icon_with_text_home_3")}>
              <TrustedDestination style={iconStyles} />
            </IconWithText>
          </Col>
          <Col xs={12} sm={6} md={3} className="icon_with_text_home_4">
            <IconWithText text={props.t("icon_with_text_home_4")}>
              <TalkIcon style={iconStyles} />
            </IconWithText>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(IconsLine);
