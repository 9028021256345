import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import FilterSideWrapper from "components/common/FilterSideWrapper";
import Faq from "components/Faq";

const FaqPage = () => {
  const { t } = useTranslation();

  return (
    <FilterSideWrapper>
      <div className="faq-page page-inner">
        <h1>
          {t("seo_faq_title")}
        </h1>
        <Helmet>
          <title>{t("seo_faq_title")}</title>
          <meta name="description" content={t("seo_faq_description")} />
        </Helmet>
        <Faq
          items={[
            { title: t("faq_1_title"), content: t("faq_1_content") },
            { title: t("faq_2_title"), content: t("faq_2_content") },
            { title: t("faq_3_title"), content: t("faq_3_content") },
            { title: t("faq_4_title"), content: t("faq_4_content") },
            { title: t("faq_5_title"), content: t("faq_5_content") },
          ]}
        />
      </div>
    </FilterSideWrapper>
  );
};

export default FaqPage;
