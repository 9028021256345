export const LOCATIONS = "LOCATIONS";
export const LOCATIONS_MAP = "LOCATIONS_MAP";
export const YACHT_TYPE = "YACHT_TYPE";
export const FILTER = "FILTER";
export const BLOG = "BLOG";
export const BLOG_PAGE = "BLOG_PAGE";
export const BLOG_PAGE_PAGINATION = "BLOG_PAGE_PAGINATION";
export const BLOG_SINGLE = "BLOG_SINGLE";
export const TESTIMONIALS = "TESTIMONIALS";
export const OFFERS_HOME = "OFFERS_HOME";
export const OFFERS_DESTINATION = "OFFERS_DESTINATION";
export const OFFERS = "OFFERS";
export const OFFER = "OFFER";
export const OFFERS_PAGINATION = "OFFERS_PAGINATION";
export const YACHT_BRANDS = "YACHT_BRANDS";
export const ENQUIRY = "ENQUIRY";
export const COUNTRIES_ENQUIRY = "COUNTRIES_ENQUIRY";
export const COUNTRIES = "COUNTRIES";
export const WISHLIST_CLICKED = "WISHLIST_CLICKED";
export const WISHLIST_API = "WISHLIST_API";
export const WISHLIST_API_SAGA = "WISHLIST_API_SAGA";
export const SPECIAL_OFFERS = "SPECIAL_OFFERS";
