import { COUNTRIES_ENQUIRY } from "../constants/actionTypes";

// locations reducer
export default function countriesEnquiry(state = [], action) {
  switch (action.type) {
    case COUNTRIES_ENQUIRY:
      return action.countriesEnquiry || [];

    // initial state
    default:
      return state;
  }
}
