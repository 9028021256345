import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {language} from './constants'

const translation = require(`./locales/${process.env.REACT_APP_LANGUAGE || 'si'}/common.json`)

i18n.use(initReactI18next).init({
  lng: language,
  resources: {
    [language]: {common: translation}
  },

  //debug: true,
  react: {
    useSuspense: false,
  },

  defaultNS: "common",

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

export default i18n;
