import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeIcon = (props) => {
  const { t } = useTranslation();
  return (
    <div className="home-icon">
      <Link to={t(`common:${props.link}`) || "#"}>
        <div className="home-icon--icon">{props.children}</div>
      </Link>
      <div className="home-icon--title">
        <Link to={t(`common:${props.link}`) || "#"}>
          {t(`common:${props.title}`)}
        </Link>
      </div>
      <div className="home-icon--text">
        <div dangerouslySetInnerHTML={{ __html: t(`common:${props.text}`) }} />
      </div>
    </div>
  );
};

export default HomeIcon;
