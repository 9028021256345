import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Alert from "react-bootstrap/Alert";

import { formatOfferPeriod, formatOfferPrice } from "utils/offerUtils";
import { getValuesFromUrl, valuesToSearch } from "utils/search_utils";
import Loader from "../components/Loader";
import Compass from "../components/icons/Compass";
import OfferTeaser from "components/OfferTeaser";
import { OFFERS_URL } from "constants/index";
import { handleHeartClick } from "utils/wishlistUtils";
import { wishlistClickedReducerAction } from "actions/wishlist";
import { LayoutWithSidebar } from "components/BaseLayout";
import DestinationTeaser from "components/DestinationTeaser";
import QuickContact from "components/QuickContact";
import OfferFilter from "components/filter/OfferFilter";
import OffersHeader from "components/OffersHeader";
import OfferInquiry from "components/OfferInquiry";
import { createOfferInquiry } from "api/base";
import SidebarTestimonials from "components/SidebarTestimonials";

const NoResults = () => {
  const { t } = useTranslation();
  return (
    <div className="no-results">
      <Compass />
      <div className="no-results__text">{t("no_offers")}</div>
    </div>
  );
};

const OffersPage = ({
  error,
  results,
  next,
  dispatch,
  location,
  loading,
  wishlist,
  count,
  destination,
  countries,
}) => {
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const [enquiryProps, setEnquiryProps] = useState({});
  const [displayTotalPrice, setDisplayTotalPrice] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch({ type: "SEARCH", values: {}, search: location.search });
  }, [dispatch, location.search]);
  const noResults = results !== null && results.length === 0;
  let history = useHistory();
  const values = getValuesFromUrl(location.search);
  return (
    <>
      <Helmet>
        <title>
          {t("seo_offers_title", {
            destination: destination ? `: ${destination.name}` : "",
          })}
        </title>
        <meta name="description" content={t("offers_seo_desc")} />
      </Helmet>
      <LayoutWithSidebar
        rowClassName="offers-row"
        mainClassName="offers-wrapper"
        className="offers"
        sidebar={
          <>
            <OfferFilter />
            <QuickContact />
            <SidebarTestimonials />
          </>
        }
      >
        <OffersHeader
          onSetOrdering={(o) =>
            history.push(OFFERS_URL + "?" + valuesToSearch({ ...values, o }))
          }
          orderValue={values.o}
          displayTotalPrice={displayTotalPrice}
          setDisplayTotalPrice={setDisplayTotalPrice}
        />
        {error && (
          <Alert className="mt-3" variant="danger">
            {t(error)}
          </Alert>
        )}
        {loading && <Loader />}
        {destination && <DestinationTeaser destination={destination} />}
        {noResults && <NoResults />}
        {count ? (
          <p className="offers_num_result">
            {t("offers_num_result", { count })}
          </p>
        ) : (
          ""
        )}
        {results && (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => next && dispatch({ type: "SEARCH_LOAD_MORE", next })}
            hasMore={!!next}
            loader={<Loader key={0} />}
            threshold={1200}
          >
            {results.map((offer, index) => (
              <OfferTeaser
                displayTotalPrice={displayTotalPrice}
                key={offer.id}
                offer={offer}
                inWishlist={wishlist.array.contains(offer.id.toString())}
                handleHeartClick={(id) => {
                  const result = handleHeartClick(id);
                  dispatch(
                    wishlistClickedReducerAction({
                      ...result,
                      success: true,
                    })
                  );
                }}
                onEnquiry={() => {
                  setEnquiryProps({
                    offerId: offer.id,
                    yachtModel: offer.yacht.yacht_model.name,
                    yachtTerm: formatOfferPeriod(offer),
                    yachtPrice: formatOfferPrice(offer),
                  });
                  setShowEnquiryModal(true);
                }}
              />
            ))}
          </InfiniteScroll>
        )}
      </LayoutWithSidebar>
      <OfferInquiry
        {...enquiryProps}
        onClose={() => setShowEnquiryModal(false)}
        show={showEnquiryModal}
        countries={countries}
        onSubmit={createOfferInquiry}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    error: state.search.error,
    results: state.search.results,
    next: state.search.next,
    loading: state.search.loading,
    wishlist: state.wishlist,
    destination: state.search.destination,
    count: state.search.count,
    yachtType: state.yachtType,
    brands: state.brands,
    countries: state.countriesEnquiry || [],
  };
}

export default connect(mapStateToProps)(OffersPage);
