import { BLOG_PAGE, BLOG_PAGE_PAGINATION } from "../constants/actionTypes";

// locations reducer
export default function blog_page(state = [], action) {
  switch (action.type) {
    case BLOG_PAGE:
      return action.blogs || [];

    case BLOG_PAGE_PAGINATION:
      let blogs = [];
      if (state.results) {
        blogs = state.results.concat(action.blogs.results);
      }

      return { ...action.blogs, results: blogs };
    // initial state
    default:
      return state;
  }
}
