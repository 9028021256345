import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import Loader from "../components/Loader";
import { getOffer } from "api/search";
import OfferDetail from "components/OfferDetail";
import { handleHeartClick } from "utils/wishlistUtils";
import { wishlistClickedReducerAction } from "actions/wishlist";
import { ConnectedBasicSearch } from "components/filter/OfferFilter";
import OfferInquiry from "components/OfferInquiry";
import { createOfferInquiry } from "api/base";
import { formatOfferPeriod, formatOfferPrice } from "utils/offerUtils";
import NotFound from "components/NotFound";

const OfferDetailPage = ({ wishlist, dispatch, countries }) => {
  const [error, setError] = useState();
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  let { yacht_id, id } = useParams();
  const [offer, setOffer] = useState();
  useEffect(() => {
    const loadOffer = async () => {
      try {
        const data = await getOffer(id);
        setOffer(data);
        window.scrollTo(0, 0);
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setError("offer_not_found");
        }
        setError("error");
      }
    };
    loadOffer();
  }, [yacht_id, id]);
  if (error) {
    return <NotFound />;
  }
  if (!offer) {
    return <Loader />;
  }
  return (
    <>
      <OfferDetail
        offer={offer}
        inWishlist={wishlist.array.contains(offer.id.toString())}
        setShowEnquiryModal={setShowEnquiryModal}
        handleHeartClick={() => {
          dispatch(
            wishlistClickedReducerAction({
              ...handleHeartClick(offer.id),
              success: true,
            })
          );
        }}
        searchComponent={<ConnectedBasicSearch />}
      />
      <OfferInquiry
        show={showEnquiryModal}
        offerId={offer.id}
        yachtModel={offer.yacht.yacht_model.name}
        yachtTerm={formatOfferPeriod(offer)}
        yachtPrice={formatOfferPrice(offer)}
        onClose={() => setShowEnquiryModal(false)}
        countries={countries}
        onSubmit={createOfferInquiry}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist,
    countries: state.countriesEnquiry || [],
  };
}

export default connect(mapStateToProps)(OfferDetailPage);
