import { call, put, select } from "redux-saga/effects";
import { searchOffers, searchUrl } from "../api/search";

const getPreviousSearch = state => state.search.currentSearch

export function* search(action) {
  const currentSearch = action.search
  const url = searchUrl(currentSearch)
  const previousSearch = yield select(getPreviousSearch);
  /* eslint-disable-next-line eqeqeq */
  if (currentSearch == previousSearch) {
    return
  }
  yield put({
    type: "SET_LOADING",
  })
  try {
    const data = yield call(searchOffers, url);
    yield put({
      type: "SET_SEARCH_RESULTS",
      data,
      currentSearch,
    });
  } catch (e) {
    yield put({
      type: "SET_ERROR",
      error: "error_search_loading",
    })
  }


}

export function* searchLoadMore(action) {
  const data = yield call(searchOffers, action.next);
  yield put({
    type: "ADD_SEARCH_RESULTS",
    data,
  });
}
