import { combineReducers } from "redux";
import search from "./search";

import yachtType from "./yachtType";
import blog from "./blog";
import blog_page from "./blog_page";
import brands from "./brands";
import countriesEnquiry from "./countriesEnquiry";
import countries from "./countries";
import blog_single from "./blog_single";
import wishlist from "./wishlist";

// main reducers
export const reducers = combineReducers({
  // new
  search,

  // old
  yachtType: yachtType,
  blog: blog,
  blog_page: blog_page,
  blog_single: blog_single,
  brands: brands,
  countriesEnquiry: countriesEnquiry,
  countries: countries,
  wishlist: wishlist,
});
