import React from "react";

const SailmasterSiLe = () => (
  <svg
    className="sailmaster-si-le"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 507.75 154.2"
  >
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M173,68.62v23h-5.08v-23h-9.12V64.41h23.33v4.21Z"
        />
        <path
          className="cls-1"
          d="M186.89,91.66V69h4.23v9.84h12.44V69h4.23v22.7h-4.23V82.37H191.13v9.29Z"
        />
        <path
          className="cls-1"
          d="M232.37,69v3.61H219v6h12V82H219v6.07H232.8v3.6h-18V69Z"
        />
        <path
          className="cls-2"
          d="M248,68a7.21,7.21,0,0,0-3.64.82A2.67,2.67,0,0,0,243,71.29a2.8,2.8,0,0,0,1.42,2.53,22.87,22.87,0,0,0,6,1.89,17.48,17.48,0,0,1,7,2.85,6.43,6.43,0,0,1,2.35,5.4,6.9,6.9,0,0,1-3,5.79A12.67,12.67,0,0,1,249,92a19.4,19.4,0,0,1-12.52-4.4l3.18-3.47a15.15,15.15,0,0,0,9.47,3.58,7,7,0,0,0,3.89-1,2.91,2.91,0,0,0,1.44-2.53,2.77,2.77,0,0,0-1.36-2.46,15.2,15.2,0,0,0-4.67-1.6,45.73,45.73,0,0,1-5-1.32,11.72,11.72,0,0,1-3.05-1.58,6.34,6.34,0,0,1-2.67-5.61,6.61,6.61,0,0,1,3-5.83,13.13,13.13,0,0,1,7.51-2,19.75,19.75,0,0,1,5.72.86A15.54,15.54,0,0,1,258.84,67l-2.71,3.47a10.62,10.62,0,0,0-3.61-1.79A15.23,15.23,0,0,0,248,68Z"
        />
        <path
          className="cls-2"
          d="M268.88,86.49l-2.51,5.16h-4.52l11-22.7h4.52l11,22.7h-4.52l-2.51-5.16ZM279.71,83l-4.56-9.35L270.6,83Z"
        />
        <path className="cls-2" d="M291.94,69h4.23v22.7h-4.23Z" />
        <path className="cls-2" d="M303.13,91.66V69h4.23V88H318.8v3.64Z" />
        <path
          className="cls-2"
          d="M327.66,91.66h-4.23V69H330l7.57,14.26L345.16,69h6.56v22.7H347.5V73.92l-8.75,15.75h-2.37l-8.71-15.75Z"
        />
        <path
          className="cls-2"
          d="M362.23,86.49l-2.51,5.16H355.2l11-22.7h4.52l11,22.7H377.3l-2.51-5.16ZM373.07,83l-4.55-9.35L364,83Z"
        />
        <path
          className="cls-2"
          d="M392.88,71.94a6,6,0,0,0-3,.68,2.23,2.23,0,0,0-1.18,2.06,2.34,2.34,0,0,0,1.18,2.11,19.18,19.18,0,0,0,5,1.57,14.56,14.56,0,0,1,5.81,2.37,5.36,5.36,0,0,1,2,4.5,5.76,5.76,0,0,1-2.47,4.82,10.58,10.58,0,0,1-6.49,1.85,16.15,16.15,0,0,1-10.43-3.67l2.65-2.89a12.61,12.61,0,0,0,7.89,3,5.82,5.82,0,0,0,3.25-.8,2.43,2.43,0,0,0,1.2-2.11,2.31,2.31,0,0,0-1.13-2,12.62,12.62,0,0,0-3.89-1.33A37.7,37.7,0,0,1,389,81a9.78,9.78,0,0,1-2.54-1.31A5.28,5.28,0,0,1,384.25,75a5.51,5.51,0,0,1,2.53-4.85A10.94,10.94,0,0,1,393,68.4a16.41,16.41,0,0,1,4.77.71,12.88,12.88,0,0,1,4.09,2L399.64,74a8.86,8.86,0,0,0-3-1.49A12.75,12.75,0,0,0,392.88,71.94Z"
        />
        <path
          className="cls-2"
          d="M417.36,72.46v19.2h-4.23V72.46h-7.6V69H425v3.51Z"
        />
        <path
          className="cls-2"
          d="M447.16,69v3.61H433.78v6h12V82h-12v6.07h13.81v3.6h-18V69Z"
        />
        <path
          className="cls-2"
          d="M473,76.33q0,5.62-5.41,7.08l6.56,8.25h-5.38l-6-7.63h-5.56v7.63H453V69h9.32q5.74,0,8.21,1.75T473,76.33Zm-5.63,3.21a3.84,3.84,0,0,0,1.29-3.23,3.32,3.32,0,0,0-1.33-3.05,9.6,9.6,0,0,0-4.73-.83h-5.38v8.12h5.27Q466.09,80.55,467.39,79.54Z"
        />
        <path
          className="cls-1"
          d="M478.46,91.25a2,2,0,0,1-.7-1.55,2,2,0,0,1,.7-1.53,2.43,2.43,0,0,1,1.7-.63,2.5,2.5,0,0,1,1.71.63,2,2,0,0,1,.71,1.53,2,2,0,0,1-.71,1.55,2.47,2.47,0,0,1-1.71.64A2.41,2.41,0,0,1,478.46,91.25Z"
        />
        <path
          className="cls-1"
          d="M499.52,87a4.21,4.21,0,0,1-1.78,3.55,7.39,7.39,0,0,1-4.55,1.33,13.41,13.41,0,0,1-3.89-.61,12.09,12.09,0,0,1-3.56-1.73L487.48,87a10.35,10.35,0,0,0,5.81,2,3.26,3.26,0,0,0,1.94-.51,1.55,1.55,0,0,0,.71-1.3q0-1.17-3.42-2.1l-.39-.12q-5.36-1.31-5.36-4.38a4,4,0,0,1,1.86-3.47,8.24,8.24,0,0,1,4.83-1.3,10.79,10.79,0,0,1,5.62,1.58l-1.36,2.43a8.69,8.69,0,0,0-4.55-1.26,4.52,4.52,0,0,0-2.1.42,1.36,1.36,0,0,0-.81,1.27,1.17,1.17,0,0,0,.84,1.11,8.57,8.57,0,0,0,1.63.5c.7.16,1.32.31,1.86.47s1.06.33,1.56.53a10.69,10.69,0,0,1,1.63.82A3.56,3.56,0,0,1,499.52,87Z"
        />
        <path
          className="cls-1"
          d="M503.85,73a1.91,1.91,0,0,1,0-2.92,2.42,2.42,0,0,1,3.23,0,1.92,1.92,0,0,1,0,2.92,2.43,2.43,0,0,1-3.23,0Zm3.39,18.65h-3.61V76.11h3.61Z"
        />
        <path
          className="cls-1"
          d="M163.24,115.14V105.09h1.6l3.64,5.08a27.8,27.8,0,0,1,2,3.27h0a39.54,39.54,0,0,1-.17-4.13v-4.2h1.38v10.05h-1.48l-3.61-5.1a31.29,31.29,0,0,1-2.12-3.36h-.05c.08,1.27.12,2.48.12,4.15v4.3Z"
        />
        <path
          className="cls-1"
          d="M198.1,112l-1.18,3.16H195.4l3.86-10.05H201l3.88,10.05h-1.57L202.13,112Zm3.73-1-1.11-2.89c-.25-.66-.42-1.25-.59-1.83h0c-.17.6-.35,1.21-.57,1.82L198.4,111Z"
        />
        <path
          className="cls-1"
          d="M231.15,105.09h1.47v6.72c0,2.67-1.48,3.49-3.44,3.49a5.3,5.3,0,0,1-1.57-.24l.22-1.06a3.87,3.87,0,0,0,1.23.2c1.32,0,2.09-.52,2.09-2.49Z"
        />
        <path
          className="cls-1"
          d="M262.88,110.43h-4.42V114h4.93v1.09H257V105.09h6.14v1.09h-4.67v3.18h4.42Z"
        />
        <path
          className="cls-1"
          d="M296.79,110.72c-.08-1.4-.18-3.09-.17-4.34h-.05c-.39,1.18-.86,2.43-1.43,3.82l-2,4.88H292l-1.84-4.79c-.54-1.42-1-2.72-1.32-3.91h0c0,1.25-.12,2.94-.22,4.44l-.3,4.31h-1.4l.79-10.05h1.88l1.94,4.86c.47,1.24.86,2.34,1.15,3.39h0a35.21,35.21,0,0,1,1.2-3.39l2-4.86h1.87l.71,10.05h-1.43Z"
        />
        <path
          className="cls-1"
          d="M348.19,105.21A19.31,19.31,0,0,1,351,105a5.09,5.09,0,0,1,3.19.84,2.56,2.56,0,0,1,1,2.1,2.77,2.77,0,0,1-.88,2.15,5.08,5.08,0,0,1-3.47,1.1,5.64,5.64,0,0,1-1.18-.09v4h-1.47Zm1.47,4.85a5.67,5.67,0,0,0,1.21.1c1.77,0,2.85-.76,2.85-2.15s-1.06-2-2.68-2a6.85,6.85,0,0,0-1.38.1Z"
        />
        <path className="cls-1" d="M379.08,105.09h1.47v9h4.86v1.09h-6.33Z" />
        <path
          className="cls-1"
          d="M418,110a5,5,0,0,1-5.28,5.29,4.87,4.87,0,0,1-5.11-5.1,5,5,0,0,1,5.28-5.28A4.83,4.83,0,0,1,418,110Zm-8.82.16c0,2.15,1.32,4.07,3.62,4.07s3.65-1.9,3.65-4.18c0-2-1.18-4.09-3.63-4.09S409.2,108,409.2,110.17Z"
        />
        <path
          className="cls-1"
          d="M444.34,115.14l-3.71-10.05h1.58l1.77,5c.49,1.36.91,2.58,1.22,3.76h0c.32-1.16.79-2.43,1.3-3.74l1.92-5H450L446,115.14Z"
        />
        <path className="cls-1" d="M474.65,105.09v10.05h-1.47V105.09Z" />
        <path className="cls-1" d="M499.14,105.09h1.47v9h4.86v1.09h-6.32Z" />
        <path
          className="cls-5"
          d="M65.38,20.14s62.86,25.26,59.87,58.73-61.31,59.84-61.31,59.84,21-26.81,20.05-59.05S65.38,20.14,65.38,20.14Z"
        />
        <path
          className="cls-5"
          d="M65.38,20.14a174.55,174.55,0,0,1,20.06,9.51Q90.3,32.33,95,35.34c3.13,2,6.18,4.13,9.1,6.43a89.58,89.58,0,0,1,8.4,7.37,64.54,64.54,0,0,1,7.27,8.55,40.1,40.1,0,0,1,5.11,10.1,28.4,28.4,0,0,1,1.4,11.38l-.38,2.74c-.18.93-.43,1.88-.65,2.82s-.57,1.82-.85,2.73a24.29,24.29,0,0,1-1,2.65,51.57,51.57,0,0,1-5.65,9.8,84.45,84.45,0,0,1-7.27,8.58c-2.61,2.68-5.38,5.2-8.23,7.6s-5.83,4.66-8.85,6.84-6.12,4.25-9.29,6.19-6.38,3.82-9.65,5.6-6.56,3.47-10,5l-4.23,2L63,138c1.53-2,3-4.22,4.37-6.43.7-1.1,1.34-2.24,2-3.35l1.89-3.43L73,121.29c.58-1.17,1.09-2.36,1.65-3.55s1-2.4,1.52-3.6,1-2.42,1.4-3.66.89-2.45,1.27-3.7L80,103c.39-1.25.63-2.53,1-3.79s.63-2.53.81-3.82l.66-3.85c.16-1.29.27-2.59.4-3.88L83,85.75c0-.65,0-1.3.06-1.95l.09-3.91c0-1.26-.13-2.61-.18-3.92s-.12-2.63-.3-3.92l-.43-3.88-.64-3.87-.32-1.94-.42-1.91c-.28-1.28-.54-2.57-.85-3.84-.67-2.54-1.28-5.09-2.09-7.59s-1.63-5-2.51-7.46L74,37.88l-1.52-3.64-1.62-3.6-1.72-3.55C68,24.74,66.73,22.4,65.38,20.14Zm0,0c1.44,2.21,2.77,4.49,4,6.8S71.86,31.62,73,34l1.64,3.61,1.53,3.66c1,2.46,1.93,4.92,2.74,7.44s1.59,5,2.32,7.59c.33,1.28.62,2.58.93,3.86l.46,1.94.37,2L83.69,68l.51,4c.2,1.32.3,2.64.38,4s.19,2.6.25,4l0,4c0,.66,0,1.33,0,2l-.16,2c-.12,1.32-.2,2.66-.34,4l-.61,4c-.16,1.33-.47,2.62-.76,3.93s-.52,2.62-.91,3.89l-1.1,3.84c-.38,1.28-.85,2.53-1.27,3.78s-.9,2.51-1.4,3.74-1,2.48-1.53,3.69-1.07,2.44-1.65,3.63l-1.78,3.57-1.91,3.5c-.68,1.14-1.32,2.31-2,3.44-1.39,2.28-2.85,4.48-4.48,6.66l-1.41-1.79c3.29-1.54,6.57-3.26,9.79-5s6.39-3.64,9.5-5.59,6.2-4,9.18-6.08,5.93-4.33,8.74-6.67,5.53-4.8,8.11-7.39a80.67,80.67,0,0,0,7.11-8.29,49.31,49.31,0,0,0,5.47-9.34,23.26,23.26,0,0,0,1-2.51c.27-.85.64-1.69.82-2.55s.44-1.69.61-2.58l.4-2.74A27.26,27.26,0,0,0,123,68.38a38.43,38.43,0,0,0-4.71-9.71,62.8,62.8,0,0,0-6.93-8.45,88.47,88.47,0,0,0-8.14-7.4A151.87,151.87,0,0,0,85.06,30.31q-4.77-2.8-9.7-5.35C72.08,23.27,68.77,21.62,65.38,20.14Z"
        />
        <polygon
          className="cls-6"
          points="57.2 0 60.89 124.5 54.7 0.1 57.2 0"
        />
        <path
          className="cls-6"
          d="M75.41,141.54l1.42.06c.92.05,2.28.1,4,.26s3.81.34,6.21.68a56,56,0,0,1,8.06,1.61,16.8,16.8,0,0,1,2.33.87,6.21,6.21,0,0,1,1.3.8,3.28,3.28,0,0,1,.73.83,2.48,2.48,0,0,1,.33,1.67,2.73,2.73,0,0,1-.67,1.37,3.87,3.87,0,0,1-.76.67,8.35,8.35,0,0,1-1.37.76,21.09,21.09,0,0,1-2.58.92,52.22,52.22,0,0,1-10.82,2,77.9,77.9,0,0,1-11.69-.07c-3.94-.3-7.9-.82-11.83-1.45s-7.82-1.48-11.62-2.36S41,148.24,37.4,147.23s-7-2.09-10.2-3.17-6.25-2.13-9-3.18c-5.56-2-10.09-4-13.25-5.38S0,133.22,0,133.22l19,4.93,9.2,2.33L38.51,143c3.55.87,7.26,1.61,11,2.44s7.57,1.52,11.39,2.15,7.65,1.23,11.42,1.58a92.08,92.08,0,0,0,11,.5,59.76,59.76,0,0,0,10.15-1.19,17.63,17.63,0,0,0,2.17-.6,5.23,5.23,0,0,0,.81-.36c.21-.08.2-.25.06.24a.77.77,0,0,0,.11.53l0-.06a2.89,2.89,0,0,0-.63-.47,12.44,12.44,0,0,0-1.82-.9,73.47,73.47,0,0,0-7.5-2.44c-2.3-.62-4.32-1.15-6-1.57Z"
        />
        <path
          className="cls-3"
          d="M108,103.74c2.8-4.59,8.27-14.35,9.87-22.8a31.26,31.26,0,0,0-1.16-14.8c2.65,5.44,5.09,13.11,2.87,20.39A35.11,35.11,0,0,1,108,103.74Z"
        />
        <path
          className="cls-4"
          d="M112.64,58.3s6.72,11.23,4.58,22.52S105.77,106,105.77,106s10.19-5.65,14.37-19.32S112.64,58.3,112.64,58.3Z"
        />
      </g>
    </g>
  </svg>
);

export default SailmasterSiLe;
