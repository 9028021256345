import { BLOG_SINGLE } from "../constants/actionTypes";

// locations reducer
export default function blog_single(state = [], action) {
  switch (action.type) {
    case BLOG_SINGLE:
      return action.blog || [];

    // initial state
    default:
      return state;
  }
}
