import React from "react";

const RealTime = () => (
  <svg
    className="realtime"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60.5 61.6"
  >
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M29.6,2.5A27.26,27.26,0,0,1,45.9,7.9c.8-.4,1.6-.8,2.5-1.2A29.28,29.28,0,0,0,29.7,0,29.85,29.85,0,0,0,0,29.8,30.24,30.24,0,0,0,6.9,48.9l1.9-1.6A27.25,27.25,0,0,1,29.6,2.5Z"
        />
        <path
          className="cls-1"
          d="M56.9,29.8h2.5a30,30,0,0,0-4.3-15.4l-1.5,2.2A29,29,0,0,1,56.9,29.8Z"
        />
        <path
          className="cls-1"
          d="M55.8,36.4a4.7,4.7,0,1,0,4.7,4.7A4.76,4.76,0,0,0,55.8,36.4Zm0,7.3A2.7,2.7,0,1,1,58.5,41,2.69,2.69,0,0,1,55.8,43.7Z"
        />
        <path
          className="cls-1"
          d="M47.4,50.5a3.61,3.61,0,0,0-2-.6,3.53,3.53,0,0,0-3,1.6,3.65,3.65,0,0,0,1,5,3.61,3.61,0,0,0,2,.6,3.53,3.53,0,0,0,3-1.6A3.65,3.65,0,0,0,47.4,50.5Zm-.6,3.9a1.73,1.73,0,0,1-2.3.5,1.63,1.63,0,0,1-.5-2.3,1.51,1.51,0,0,1,1.4-.7,1.61,1.61,0,0,1,.9.3A1.58,1.58,0,0,1,46.8,54.4Z"
        />
        <path
          className="cls-1"
          d="M29.8,55a2.77,2.77,0,0,0-1.3.3,3,3,0,0,0-1.7,1.8,3.58,3.58,0,0,0,0,2.5,3.28,3.28,0,0,0,3,2,2.77,2.77,0,0,0,1.3-.3,3,3,0,0,0,1.7-1.8,3.58,3.58,0,0,0,0-2.5A3.13,3.13,0,0,0,29.8,55Zm.6,4.4a1.28,1.28,0,0,1-1.7-.7,1.7,1.7,0,0,1,0-1,1.21,1.21,0,0,1,.7-.7.9.9,0,0,1,.5-.1,1.3,1.3,0,0,1,1.2.8A1.43,1.43,0,0,1,30.4,59.4Z"
        />
        <circle className="cls-1" cx="14.2" cy="53.7" r="2.3" />
        <path
          className="cls-1"
          d="M54.2,10.3a1.4,1.4,0,0,0,0-1.4c0-.1,0-.1-.1-.2l-.2-.2a1.7,1.7,0,0,0-1.7-.3c-.1,0-.2.1-.3.1L50.5,9c-2.7,1.3-5.4,2.6-8,3.9-1.5.7-3,1.5-4.5,2.2-3.9,1.9-7.9,3.8-11.9,5.8a8.91,8.91,0,0,0-4.6,10.7,9.12,9.12,0,0,0,8.8,6.5h0a4.1,4.1,0,0,0,1.1-.1,9.47,9.47,0,0,0,6.8-4.4c3.5-5.1,7.1-10.3,10.6-15.4ZM36.1,32.2a7,7,0,0,1-5,3.4,6.6,6.6,0,0,1-7.2-4.7,6.46,6.46,0,0,1,3.3-7.8c3.9-2,7.8-3.9,11.6-5.7l.2-.1c1.5-.7,3-1.5,4.5-2.2,2.2-1.1,4.4-2.1,6.6-3.2l-3.7,5.4C43,22.3,39.6,27.2,36.1,32.2Z"
        />
      </g>
    </g>
  </svg>
);

export default RealTime;
