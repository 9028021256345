import { call, put } from "redux-saga/effects";
import { fromJS} from "immutable";

import { wishlistApiReducerAction } from "../actions/wishlist";
import ApiOffers from "../api/offers";

export function* wishlistApi(action) {
  const offers = yield call(ApiOffers.getWishList, action.payload);

  const a = [];
  offers.forEach((offer) => {
    a.push(offer.id.toString());
  });

  const counter = a.length;

  localStorage.setItem("wishlist", a.toString());

  const offersReducer = {
    offers,
    count: counter,
    array: fromJS(a),
  };

  yield put(wishlistApiReducerAction(offersReducer));
}
