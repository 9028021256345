const initialState = {
  results: null,
  next: null,
  loading: false,
  loadingMore: false,
  currentSearch: null,
  destination: null,
  count: null,
  error: null,
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        results: null,
        count: null,
      }
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
        error: false,
      }
    case "SET_SEARCH_RESULTS":
      return {
        ...state,
        next: action.data.next,
        results: action.data.results,
        loading: false,
        currentSearch: action.currentSearch,
        destination: action.data.destination,
        count: action.data.count,
        error: false,
      };
    case "ADD_SEARCH_RESULTS":
      return {
        ...state,
        next: action.data.next,
        results: state.results.concat(action.data.results),
        error: false,
      };
    default:
      return state;
  }
}
