import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LayoutQuickSearchSidebar } from "components/BaseLayout";
import ContactForm, {IdCard} from "components/ContactForm";
import { createContact } from 'api/base';

function ContactPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("contact_seo_title")}</title>
        <meta name="description" content={t("contact_seo_desc")} />
      </Helmet>
      <LayoutQuickSearchSidebar>
        <div class="page-inner">
        <h1>{t("contact_us")}</h1>
        <IdCard />
        <ContactForm 
          onSubmit={createContact}
        />
        </div>
      </LayoutQuickSearchSidebar>
    </>
  );
}

export default ContactPage;
