import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // initialized i18next instance
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { properLanguage } from "constants/index";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "components/ScrollToTop";
import App from "./App";

import moment from "moment";
import "moment/locale/sl";
import "moment/locale/it";
moment.locale(properLanguage);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://dd8fab38768a411d988af3a213bd039c@o38387.ingest.sentry.io/5559422",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// render the main component
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
