import React from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-parallax";

import HomeFilter from "components/filter/HomeFilter";
import IconsLine from "components/Home/IconsLine";

const Header = ({ onSearch, searchDestinations, yachtType }) => {
  const { t } = useTranslation();

  const description = t("description")
    .split("\n")
    .map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

  return (
    <header className="home-header">
      <Parallax
        className="home-header__paralax"
        strength={300}
        bgImage="/static/media/header-opt.jpg"
      >
        <Container className="home-header__container">
          <div className="home-header__content">
          <h1>{t("page_name")}</h1>
          <h2>{description}</h2>
          <HomeFilter
            searchDestinations={searchDestinations}
            yachtType={yachtType}
            onSubmit={onSearch}
          />
          </div>
        </Container>
        <IconsLine />
      </Parallax>
    </header>
  );
};

export default Header;
